import { LitElement, html, css } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js'
import { EditorContent } from './ctx-editor-content-event';
import { SnippetInsertValue, SnippetWrapValue } from './ctx-snippet-types';

@customElement('ctx-content-textarea')
export class CtxContentTextArea extends LitElement {
  static styles = [
    css`
        textarea {
            border: 0.5px solid #d1d5db;
            box-sizing: border-box;
            height: 100%;
            max-height: 100%;
            min-height: 200px;
            overflow-y: auto;
            padding: 0.5rem;
            resize: none;
            width: 100%;
        }
    `
  ];

  @query("#editor") editor: HTMLTextAreaElement;

  @property({ type: Boolean }) readonly: boolean = false;

  @state() cursorPosition: number;
  @state() private _value: string = '';

  get value(): string {
    return this._value;
  }

  set value(newValue: string) {
    this._value = newValue;

    this.dispatchEvent(new CustomEvent("content-changed", { detail:  {
      content: this._value,
      cursorPosition: this.cursorPosition
    } }));
    this.requestUpdate();
  }

  handleContentChange(content: EditorContent) {
    if (content.behaviour === "insert") {
      this._insertContent(content.value as SnippetInsertValue);
    }

    if (content.behaviour === "wrap") {
      this._wrapSelectedContent(content.value as SnippetWrapValue);
    }
  }

  private _contentChanged = async (event: InputEvent) => {
    const target = event.target as HTMLTextAreaElement;
    this.value = target.value;
    this._setCursorPosition();
  }

  private _insertContent(content: string | SnippetInsertValue) {
    var contentToInsert = typeof content === 'string' ? content : content.snippet; 

    let rawContentHead = this.value.substring(0, this.cursorPosition);
    let rawContentTail = this.value.substring(this.cursorPosition, this.value.length);

    this.cursorPosition = rawContentHead.length + contentToInsert.length;

    this.value = `${ rawContentHead }${ contentToInsert }${ rawContentTail }`;
    this.editor.value = this.value;

    this.editor.focus();
    this.editor.setSelectionRange(this.cursorPosition, this.cursorPosition);
  }

  private _setCursorPosition() {
    this.cursorPosition = this.editor.selectionStart;
  }

  private textAreaClickHandler = async () => {
    this._setCursorPosition();
    this.value = this.value;
  }

  private _wrapSelectedContent(value: SnippetWrapValue) {
    const start = this.editor.selectionStart;
    const end = this.editor.selectionEnd;

    if (start === end)
      return;

    const selectedContent = this.value.slice(start, end);

    this.value = `${this.value.slice(0, start)}${value.pre}${selectedContent}${value.post}${this.value.slice(end)}`;
  }

  render() {
    return html`<textarea ?readonly=${this.readonly} id="editor" class="component" .value=${this.value} @click=${this.textAreaClickHandler} @input=${this._contentChanged}></textarea>`;
  }
}
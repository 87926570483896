import appInsights from './../application-insights';
import { LitElement, html, css, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js'
import { MessagePromiseEvent, MessageDetail, MessagePromise } from 'src/modules/helpers/message-helper';
import { later } from 'src/modules/helpers/promise-helper';
import { AlertMessage } from './alert-message';
AlertMessage;
import "@material/mwc-icon";

//cancelable promise
//https://stackoverflow.com/questions/22707475/how-to-make-a-promise-from-settimeout 

type MessageType = { message: string, level: "error" | "success" | "information", timer: Promise<MessageType>, showProgress: boolean }

@customElement('message-manager')
export class MessageManager extends LitElement {
  static styles = [
    css`
      :host {
        display: flex;
        position: fixed;
        z-index: 1;
        flex-direction: column;
        gap: 1vh;
        inset-block-end: 1em;
        inset-inline-end: 1em;

        width:320px;
      }

      .alert-message {
        box-shadow: rgba(0, 0, 0, 0.52) 0px 6px 13px -2px;
      }

      fluent-progress-ring {
        --density: -3;
      }
    `
  ];

  @state() Messages: MessageType[] = []

  messageHandler = (e: CustomEvent<MessageDetail>) => {
    this.#addMessage(e.detail.message, e.detail.level);
  }

  #addMessage = (message: string, level: "error" | "success") => {
    var newMessage: MessageType = {
      message: message,
      level: level,
      showProgress: false,
      get timer() {
        return later(3000, this)
      }
    }

    this.Messages = [...this.Messages, newMessage];
    newMessage.timer.then((x) =>
      this.removeMessage(x)
    )

    console.log("message added")

  }
  actionMessageHandler = async (e:MessagePromiseEvent<any>)=> {
      console.log("message-manage-action start");

      var newMessage: MessageType = {
        message: e.messages.progress,
        level: "information",
        timer: null,
        showProgress: e.messages.progress == null
      }
      this.Messages = [...this.Messages, newMessage];
  
    try {
      await e.promise;
      this.removeMessage(newMessage);
      // maybe it would be better to update message, or replace message (but at this stage it's unlikely to be there for long)
      this.#addMessage(e.messages.success, "success")
    } catch (ex) {
      console.log("promise error")
      this.removeMessage(newMessage);
      this.#addMessage(e.messages.failed, "error")
      appInsights.trackException(ex)
    } finally {
      console.log("message-manage-action end");
    }
      
  }

  promiseMessageHandler = async (e: CustomEvent<MessagePromise<any>>) => {
    var newMessage: MessageType = {
      message: e.detail.messages.progress,
      level: "information",
      timer: null,
      showProgress: e.detail.messages.progress == null
    }
    this.Messages = [...this.Messages, newMessage];

    try {
      await e.detail.task;
      this.removeMessage(newMessage);

      // maybe it would be better to update message, or replace message (but at this stage it's unlikely to be there for long)
      this.#addMessage(e.detail.messages.success, "success")
    } catch (ex) {
      console.log("promise error")
      this.removeMessage(newMessage);
      this.#addMessage(e.detail.messages.failed, "error")
      appInsights.trackException(ex)
    }
  }

  removeMessage = (message: MessageType) => {
    this.Messages = this.Messages.filter(m => m !== message);
  }



  connectedCallback() {
    super.connectedCallback();
    window.addEventListener("interface-message", this.messageHandler);
    window.addEventListener("message-promise", this.promiseMessageHandler);
    window.addEventListener(MessagePromiseEvent.eventName, this.actionMessageHandler);
  }

  disconnectedCallback(): void {
    window.removeEventListener("interface-message", this.messageHandler);
    window.removeEventListener("message-promise", this.promiseMessageHandler);
    window.removeEventListener(MessagePromiseEvent.eventName, this.actionMessageHandler);
  }
  render() {
    if (this.Messages.length === 0) {
      return nothing;
    }

    return html`
      ${this.Messages.map(m => html`<alert-message level=${m.level}>
        ${m.showProgress ? html`<fluent-progress-ring slot="start"></fluent-progress-ring>` : nothing}
        ${m.message}
      </alert-message>`)}
    `;
  }


}

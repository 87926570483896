import appInsights from './../application-insights';

import { css, html, LitElement } from "lit";
import { repeat } from 'lit/directives/repeat.js';
import { customElement, query, state } from "lit/decorators.js";
import './embedded-powerbi-wrapper'
import { EmbeddedPowerBiWrapper } from "./embedded-powerbi-wrapper";
import * as pbi from 'powerbi-client';
import { DashboardReportAccessor, IReportApiEmbedAccessor, IReportRequest } from "src/modules/DashboardReportAccessor";
import { IEmbedCodeResponse } from "./../services";
import { PowerBiTokenUpdater } from 'src/modules/PowerBiTokenUpdater';
import { createEmbedConfig, isMobileView } from 'src/modules/helpers/powerbi-client-helpers';
import { IEmbedSettings } from './../services';
import { IEmbedConfigurationBase } from 'embed';

export type PageChangedAfterLoadDetail = { newPage: pbi.Page };
type EmbedItem = { powerBiType?:"Report"|"Dashboard", clientGroup?: string, name?: string, isMobile: boolean, loadedDatasets:Map<string,string> };
@customElement("embedded-powerbi-manager")
export class EmbeddedPowerBiManager extends LitElement {


  static styles = css`
  :host{
    display:flex
  }

  embedded-powerbi-wrapper{
    flex-grow:1
  }
  
`;

  private _report: pbi.Report;
  private reportKey: number;
  private _loaded: boolean = false;

  private readonly _reportAccessor: IReportApiEmbedAccessor;
  get reportAccessor() {
    return this._reportAccessor;
  }

  private _embeddedList: EmbedItem[]
  @state() _activeEmbed: EmbedItem;

  @query("#default") protected _firstPowerBiReport: EmbeddedPowerBiWrapper


  private _tokenUpdater: PowerBiTokenUpdater;

  constructor() {
    super();
    const isMobile= false;
    const defaultEmbed: EmbedItem = { name: "default", isMobile, loadedDatasets:new Map<string,string>() };

    this._embeddedList = [defaultEmbed];
    this._activeEmbed = defaultEmbed;
    this._reportAccessor = new DashboardReportAccessor();
  }

  bootstrap = async () => {
    await this._firstPowerBiReport.updateComplete

    var bootstrapConfig: IEmbedConfigurationBase = {
      type: 'report',
      hostname: "https://app.powerbi.com",
      embedUrl: "https://app.powerbi.com/reportEmbed", //fake because bookmarks don't work https://community.powerbi.com/t5/Issues/Javascript-API-Problem-with-BookmarksManager-when-Bootstrap-API/idi-p/1168698
    };

    // uncomment when/if we bring in screen detect
    // no need now as there are no mobile reports
    // if (this._embeddedList[0].isMobile) {
    //   bootstrapConfig.settings = {
    //     layoutType: pbi.models.LayoutType.MobilePortrait
    //   }
    // }

    this._embeddedList[0].powerBiType = "Report";
    var config = this._firstPowerBiReport.bootstrap(bootstrapConfig);
    console.log(config);
    console.log("bootstrapped");
  }


  loadReport = async (reportRequest: IReportRequest,clientEmbedSettings?: IEmbedSettings, bookmarkName?:string) => {


    await this.firstUpdated
    this.initializeDefaultActiveEmbed(reportRequest);


    const reportKey = reportRequest.reportKey;
    if (!this._tokenUpdater) {
      this._tokenUpdater = new PowerBiTokenUpdater(this);
    }

    this._loaded = false;
    if (slowLoadTimeout != null) {
      window.clearTimeout(slowLoadTimeout);

      appInsights.stopTrackEvent("report-load-event", { reportKey: this.reportKey?.toString(), action: "new-report" })
      slowLoadTimeout = null;
    }


    this.reportAccessor.SetEmbedRequestData(reportRequest);
    var embedResponse = await this.reportAccessor.GetCachedEmbedCode();

    const isMobile = clientEmbedSettings?.layoutType != null ? clientEmbedSettings?.layoutType === pbi.models.LayoutType.MobilePortrait : isMobileView();

    const mobileSettings = isMobile ? { layoutType: pbi.models.LayoutType.MobilePortrait }:{}
    await this.setOrCreateActiveEmbed(reportRequest.clientGroup, embedResponse.reportId, isMobile, reportRequest.reportKey, embedResponse.powerBiType, new Map<string,string>(Object.entries(embedResponse.datasets)))

    let config = this.getEmbedConfiguration(embedResponse);
    config.settings = { ...mobileSettings, ...config.settings, ...clientEmbedSettings };

    if(bookmarkName)
    {
      config.bookmark= { name :bookmarkName };
    }

    appInsights.startTrackEvent("LoadFrame")
    appInsights.startTrackEvent("report-load-event");

    var slowLoadTimeout = window.setTimeout(function () {
      console.log("report-load-event timer");
      appInsights.stopTrackEvent("report-load-event", { reportKey: reportKey.toString(), action: "slowtimer" })
      window.clearTimeout(slowLoadTimeout);
      slowLoadTimeout = null;
    }, 50000);

    const loadedReport = await this.loadFrame(config);

    if (slowLoadTimeout !== null) {
      // loaded ok so clear
      window.clearTimeout(slowLoadTimeout);
      slowLoadTimeout = null;
    }
    appInsights.stopTrackEvent("report-load-event", { reportKey: reportKey.toString(), action: "loaded" })


    appInsights.stopTrackEvent("LoadFrame", { reportKey: reportKey.toString() })

    this.reportKey = reportKey;
    this._report = loadedReport;
    this._loaded = true;
    this._tokenUpdater.AttachInterval(embedResponse.expiration);
    return this._report;
  };


  private initializeDefaultActiveEmbed(reportRequest: IReportRequest) {
    // this bootstrapped embed to be loaded assumes the reportData
    if (this._activeEmbed.clientGroup == null) {
      this._activeEmbed.clientGroup = reportRequest.clientGroup;
    }
  }

  private setOrCreateActiveEmbed = async (clientGroup: string, reportId: string, isMobile: boolean, reportKey: number, powerBiType: "Report"|"Dashboard", datasets:Map<string,string>): Promise<void> => {
    let currentEmbed: EmbedItem;

    // find embed to place compatible report
    if (currentEmbed == null) {
      for (const embed of this._embeddedList) {
        if (embed.powerBiType === powerBiType && embed.clientGroup === clientGroup && embed.isMobile === isMobile) // must be same client group
        {
          //should check that all loaded datasets are compatible (i.e the same dataset but with different config has not already been loaded)
          if(this.#checkDataSetsAreCompatible(embed.loadedDatasets, datasets))
            {
              console.log("compatible dataset found!");
              embed.loadedDatasets = new Map<string,string>([...embed.loadedDatasets, ...datasets])
              currentEmbed = embed;
              break;
            }
        }
      }
    }

    // could not find embed, so create a new one
    if (currentEmbed == null) {
      // create new embed and assign it, name to be unique but can hold many reports
      currentEmbed = { name: `${reportId}_${reportKey}`, loadedDatasets: new Map(datasets), clientGroup: clientGroup, isMobile, powerBiType };
      this._embeddedList.push(currentEmbed);
      console.log(this._embeddedList.length ==1? "first embed required" : "new embedded-powerbi-wrapper required");
    }

    // todo might only need to updateComplete when new nodes are created,
    if (this._activeEmbed != currentEmbed) {
      this._activeEmbed = currentEmbed;
      await this.updateComplete;
    }
  }

  #checkDataSetsAreCompatible(existingDataset:Map<string,string>, incomingDatasets:Map<string,string>) {
    for(var incomingDatasetKey of incomingDatasets.keys())
      {
        if(existingDataset.has(incomingDatasetKey)){
          if(existingDataset.get(incomingDatasetKey) !== incomingDatasets.get(incomingDatasetKey)){
            console.log("dataset: already loaded with a different configuration")
            return false;
          }
        }

      }
    return true;

  }

  fullscreen = () => this.getCurrentReport().fullscreen();


  getReportBookmarks = async (): Promise<pbi.models.IReportBookmark[]> => await this.getCurrentReport().bookmarksManager.getBookmarks();

  getReportState = async (): Promise<{ reportKey: number, state: string }> => {
    const capturedBookmark = await this.getCurrentReport().bookmarksManager.capture();
    return { reportKey: this.reportKey, state: capturedBookmark.state };
  };

  getReport = () => this.getCurrentReport();

  getPages = async (): Promise<pbi.Page[]> => {
    return this._report.getPages();
  }


  // page must be active
  getTableColumns = async (visualDescriptor: pbi.VisualDescriptor): Promise<string[]> => {
    const data = await visualDescriptor.exportData(pbi.models.ExportDataType.Summarized, 1);
    const firstRow = (data as any).data.split("\r\n")[0];
    return firstRow.split(",");
  }

  setReportState = async (state: string) => {
    await this.getCurrentReport().bookmarksManager.applyState(state);
  }

  setAccessToken = async (accessToken: string) => await this.getCurrentReport().setAccessToken(accessToken);

  applyBookmark = async (bookmarkName: string) => await this.getCurrentReport().bookmarksManager.apply(bookmarkName)

  private getCurrentReport = (): pbi.Report => this._report;


  private getCurrentEmbedElement = (): EmbeddedPowerBiWrapper => {

    const activeEmbedElement = this.shadowRoot.querySelector(`embedded-powerbi-wrapper:not([hidden])`) as EmbeddedPowerBiWrapper
    return activeEmbedElement ?? this.shadowRoot.querySelector("embedded-powerbi-wrapper");
  }

  getActivePageInfo = async (): Promise<{ displayName: string, name: string }> => {
    const activePage = await this._report.getActivePage()
    return { displayName: activePage.displayName, name: activePage.name };
  };


  private getEmbedConfiguration = (embedResponse: IEmbedCodeResponse) => {
    console.log("embed", embedResponse);
    return createEmbedConfig(embedResponse.powerBiType, embedResponse.reportId, embedResponse.embedToken, embedResponse.url)
  }


  private loadFrame = (config: pbi.IEmbedConfiguration): Promise<pbi.Report> => {

    return new Promise((resolve, reject) => {
      // todo could add a reject timeout here or somewhere

      var oldReport = this._report;
      if (oldReport) {
        console.log("unloading previous report");
        oldReport.off("loaded"); // todo remove specific event instead of all
        oldReport.off("rendered");  // todo remove specific event instead of all
        oldReport.off("pageChanged");
        oldReport.off("selectionChanged");
        oldReport.off("visualClicked");
        oldReport.off("error");
      }

      var report = this.getCurrentEmbedElement().embed(config) as pbi.Report;
      this._report = report;

      console.log("report", report.getId());

      if(config.type === "report")
      {
        report.on("pageChanged", this.pageChangedEventHandler);
        report.on("selectionChanged", this.selectionChangedEventHandler);
        report.on("visualClicked", this.visualClickedEventHandler);
      }
      

      report.on("error", (event) => {
        console.log("error", event);

        if (event instanceof Error) {
          appInsights.trackException({
            exception: event
          });
        } else {
          appInsights.trackTrace({ message: "powerbi error", properties: event })
          appInsights.trackException({
            exception: new Error("powerbi error")
          });
        }

        reject(event);
      });

      report.on("loaded", (event) => {
        console.log("loaded [%o] [%o]", event, report.getId());

        appInsights.stopTrackEvent("report-loaded", { reportId: report.getId() })
        resolve(report);
      });


      report.on("rendered", this.renderedEventHandler);
      console.log("loadFrame end")
    });
  };

  private selectionChangedEventHandler = (event: Event) => {
    console.log("selectionChanged", event);

    this.raiseUserActivityEvent("selectionChanged");
  }

  private visualClickedEventHandler = (event: Event) => {
    console.log("visualClicked", event);
    this.raiseUserActivityEvent("visualClicked");
  };

  private renderedEventHandler = (event: pbi.service.ICustomEvent<{ newPage: { displayname: string } }>) => {
    appInsights.stopTrackEvent("report-rendered", { reportId: this._report.getId() })
    console.log("rendered [%o] [%o]", event, this._report.getId());
  };


  private pageChangedEventHandler = (event: CustomEvent<{ newPage: pbi.Page }>) => {
    console.log("pageChanged", event)
    if (this._loaded) {
      const pageChangeEvent = new CustomEvent<PageChangedAfterLoadDetail>("page-changed-after-load", { detail: event.detail, bubbles: true, composed: true })
      this.getCurrentEmbedElement().dispatchEvent(pageChangeEvent);
    }
  }

  private raiseUserActivityEvent = (activityType: string) => {
    const activityEvent = new CustomEvent("userActivity", {
      detail: {
        activityType: activityType
      },
      bubbles: true
    });
    this.getCurrentEmbedElement().dispatchEvent(activityEvent);
  }

  render = () => html`
    ${repeat(this._embeddedList, (item) => item.name, (embedItem, index) => {
        const hideReport: boolean = embedItem !== this._activeEmbed;
        return html`<embedded-powerbi-wrapper ?hidden=${hideReport} .ad-key=${embedItem.clientGroup} .id=${embedItem.name} .ad-type=${embedItem.powerBiType}></embedded-powerbi-wrapper>`
      })
    }
  `;

}
import { LitElement, html, css } from 'lit';
import { customElement, state } from 'lit/decorators.js'
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { IReportNews, services } from 'src/services';

@customElement('report-news')
export class ReportNews extends LitElement {
  static styles = [
    css`
      .news {
        margin-bottom: 16px;
      }
    `
  ];

  @state() reportNews: IReportNews[] = [];

  connectedCallback() {
    super.connectedCallback();

    services.report.news().then(reportNews => {
      this.reportNews = reportNews;
    });
  }

  render() {
    return html`${this.reportNews?.map((news) =>
      html`<div class="news">${unsafeHTML(news.html)}</div>`
    )}`;
  }
}

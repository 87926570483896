import { LitElement, html, css, nothing } from 'lit';
import { property, customElement } from 'lit/decorators.js';
import { Icon } from '@material/mwc-icon';
Icon;

// todo can panels go on top of each other (dynamic z-index?)
// todo better sizing (maybe 100% for small screens)
// todo -- background theme color
// todo consider moving the the other content
// todo disablebutton property
// todo escape closes panel

@customElement('side-panel')
export class SidePanel extends LitElement {

  @property({ type: Boolean, reflect: true }) open = false;
  @property({ type: Boolean, attribute: "open-window" }) openWindow = false;
  @property({ attribute: "open-window-link" }) openWindowLink:string;

  static styles = css`
    :host { 
      display: block; 
      position:fixed;
      top:0;
      left:0;
      transition: 0.5s; 
      width:min-content;
      min-width:50%;
      max-width:100%;
      left: -100%;
      z-index:1;
      height:100vh;
      overflow:auto;
      background-color:white;

      box-sizing: border-box;
      border:2px;
      border-right-style:solid;
      border-right: 2px solid rgb(192, 192, 192);

    }

    .content-wrapper{
      padding: 2rem;
      isolation:isolate;
    }

    .close-button{
      position: sticky;
    top: 14px;
    float: right;
    border-radius: 30px;
    margin: 14px;
      background-color:transparent;
      backdrop-filter: grayscale(1) brightness(1.8) blur(15px);

    z-index: 1;
    }

    :host([open]) {
      left:0;
    }

  `;

  show = () => {
    this.open = true;
  }

  toggle = () => {
    this.open = !this.open;
  }

  hide = () => {
    this.open = false;
  }

  render() {
    return html`
     <mwc-icon-button icon="close" class="close-button" @click=${this.hide}></mwc-icon-button>
     ${this.openWindow ? html`<mwc-icon-button icon="open_in_new" class="close-button" @click=${() =>{ this.open=false;window.open(this.openWindowLink);  }} ></mwc-icon-button>`:nothing}
       <div class="content-wrapper">
          <slot>
          </slot>
        </div>
      `;
  }
}
import { LitElement, html, css, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js'

@customElement('ctx-content-select')
export class CtxContentSelect extends LitElement {
  static styles = [
    css`
        .flex {
          align-items: center;
          display: flex;
          gap: 0.25rem;
        }

        .heading {
          flex-grow: 1;
        }

        .options {
          background-color: #FFFFFF;
          border: 0.5px solid #d1d5db;
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          margin-top: 0.25rem;
          min-width: 11rem;
          padding-block: 0.25rem;
          padding-inline: 0.5rem;
          position: absolute;
          user-select: none;
          z-index: 999;
        }

        .select {
            align-items: center;
            background-color: #FFFFFF;
            border: 0.5px solid #d1d5db;
            cursor: pointer;
            display: flex;
            min-height: 1.75rem;
            min-width: 11rem;
            padding-block: 0.25rem;
            padding-inline: 0.5rem;
            user-select: none;
        }

        .select-container {
          position: relative;
        }
    `
  ];

  @property() heading: string;
  @property() headingIcon: string;

  @state() showOptions: boolean = false;

  private _boundHandleOutsideClick: (event: MouseEvent) => void;

  private _handleOutsideClick(event: MouseEvent) {
    if (!this.showOptions) return; // Don't do anything if options are already hidden

    const path = event.composedPath();
    if (!path.includes(this)) {
      this.showOptions = false;
      this.requestUpdate();
    }
  }

  private _optionSelected = async (event: CustomEvent) => {
    this._toggleShowOptions();
  }

  private _toggleShowOptions() {
    this.showOptions = !this.showOptions;
  }

  constructor() {
    super();
    this.addEventListener('option-selected', this._optionSelected);
    this._boundHandleOutsideClick = this._handleOutsideClick.bind(this);
  }

  connectedCallback() {
    super.connectedCallback();
    document.addEventListener('click', this._boundHandleOutsideClick);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    document.removeEventListener('click', this._boundHandleOutsideClick);
  }

  render() {
    return html`<div class="select-container" @click=${(e: Event) => e.stopPropagation()}>
      <div class="select" @click=${this._toggleShowOptions}> 
          ${this._renderHeading()}
          ${this._renderIcon()}
      </div>
      ${this._renderOptions()}
    </div>`;
  }

  private _renderHeading() {
    return html`<div class="heading">
      ${this.headingIcon || this.heading ? html`<div class="flex">${this._renderHeadingIcon()} ${this.heading}</div>` : html`<slot name="heading">${this.heading}</slot>`}
    </div>`;
  }

  private _renderHeadingIcon() {
    return this.headingIcon ? html`<mwc-icon>${this.headingIcon}</mwc-icon>` : nothing;
  }

  private _renderIcon() {
    return !this.showOptions ? html`<mwc-icon>keyboard_arrow_right</mwc-icon>` : html`<mwc-icon>keyboard_arrow_down</mwc-icon>`;
  }

  private _renderOptions() {
    return this.showOptions ? html`<slot class="options"></slot>` : nothing;
  }
}
import { boldSnippet, colleagueAuditSnippet, documentationSnippet, errorMessageSnippet, heroSnippet, informationMessageSnippet, italicSnippet, linkSnippet, orderedListSnippet, reportNewsSnippet, tableOfContentsSnippet, unOrderedListSnippet, warningMessageSnippet } from "./ctx-content-editor-examples";
import { SnippetCollection } from "./ctx-snippet-types";

export const snippets: SnippetCollection = {
    bold: {
        icon: 'format_bold',
        description: 'Bold',
        insert: {
            snippet: boldSnippet
        },
        wrap: {
            pre: '**',
            post: '**'
        }
    },
    documentLayout: {
        icon: 'dashboard',
        description: 'Document Layout',
        insert: {
            snippet: documentationSnippet
        }
    },
    errorAlert: {
        icon: 'cancel',
        description: 'Error Message',
        insert: {
            snippet: errorMessageSnippet
        }
    },
    hero: {
        icon: 'filter',
        description: 'Hero',
        insert: {
            snippet: heroSnippet
        }
    },
    infoAlert: {
        icon: 'info',
        description: 'Info Message',
        insert: {
            snippet: informationMessageSnippet
        }
    },
    italic: {
        icon: 'format_italic',
        description: 'Italic',
        insert: {
            snippet: italicSnippet
        },
        wrap: {
            pre: '*',
            post: '*'
        }
    },
    link: {
        icon: 'link',
        description: 'Link',
        insert: {
            snippet: linkSnippet
        }
    },
    orderedList: {
        icon: 'format_list_numbered',
        description: 'Ordered List',
        insert: {
            snippet: orderedListSnippet
        }
    },
    tableOfContents: {
        icon: 'format_list_bulleted',
        description: 'Table of Contents',
        insert: {
            snippet: tableOfContentsSnippet
        }
    },
    unorderedList: {
        icon: 'format_list_bulleted',
        description: 'Unordered List',
        insert: {
            snippet: unOrderedListSnippet
        }
    },
    warningAlert: {
        icon: 'warning',
        description: 'Warning Message',
        insert: {
            snippet: warningMessageSnippet
        }
    },
    reportNews: {
        icon: 'newspaper', 
        description: 'Report News',
        insert: {
            snippet: reportNewsSnippet
        }
    },
    colleagueAudit: {
        icon: 'checklist', 
        description: 'Colleague Audit',
        insert: {
            snippet: colleagueAuditSnippet
        }
    }
}
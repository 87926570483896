import { LitElement, html, css } from 'lit';
import { customElement } from 'lit/decorators.js'
import { choose } from 'lit/directives/choose.js';

type CheckboxState = boolean | null;
@customElement('ctx-checkbox')
export class CtxCheckbox extends LitElement {
  static styles = [
    css`
        :host {
            display: inline-block;
        }

        .checkbox-container {
            display: flex;
            align-items: center;
            cursor: pointer;
            user-select: none;  /* Prevents text selection */
        }

        .checkbox {
            width: 20px;
            height: 20px;
            border: 0.5px solid var(--checkbox-border-color, #4b5563);
            border-radius: 2px;
            margin-right: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.2s;
        }

        .icon {
            font-size: 16px;
            color: var(--icon-color, #4b5563);
        }
    `
  ];

  static formAssociated = true;

  private _internals: ElementInternals;
  private _value: CheckboxState = null;

  get value(): CheckboxState {
    return this._value;
  }

  set value(newValue: CheckboxState) {
    this._value = newValue;
    this._internals.setFormValue(newValue?.toString());
    this._dispatchChangeEvent(newValue);
    this.requestUpdate();
  }

  constructor() {
    super();
    this._internals = this.attachInternals();
  }

  connectedCallback() {
    super.connectedCallback();
    this.setAttribute('role', 'checkbox');
    this._setValue();
  }

  private _dispatchChangeEvent(value: CheckboxState) {
    this.dispatchEvent(new CustomEvent('change', {
        detail: { value },
        bubbles: true,
        composed: true
    }));
  }

  private _setValue() {
    if (this.hasAttribute('value')) {
        const attrValue = this.getAttribute('value').toLowerCase();
        if (attrValue === 'true') {
            this.value = true;
        } else if (attrValue === 'false') {
            this.value = false;
        } else {
            this.value = null;
        }
    }
  }
  
  private _toggleState() {
    if (this.value === null) {
      this.value = true;
    } else if (this.value === true) {
      this.value = false;
    } else {
      this.value = null;
    }
  }

  render() {
    return html`<div class="checkbox-container" @click=${ this._toggleState }>
        <div class="checkbox">
            ${ this.renderIcon() }
        </div>
        ${ this.renderStateContentSlot() }
    </div>`;
  }

  renderIcon() {
    return html`
      ${choose(this.value, [
        [true, () => html`<mwc-icon class="icon">check</mwc-icon>`],
        [false, () => html`<mwc-icon class="icon">close</mwc-icon>`]
      ],
      () => html``)}
    `;
  }

  renderStateContentSlot() {
    const slotName = this.value === null ? 'not-set' : this.value === true ? 'on' : 'off';
    return html`<slot name=${ slotName }></slot>`;
  }
}


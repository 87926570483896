import { html, css, LitElement } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';

import '@material/mwc-button';
import '@material/mwc-textfield';

/*
 [] remove step-id,activeStep no need at the moment
 [] add cancel or swap previous to cancel, or only show if cancel function as implementation
 [] next button spin while progressing
 [] consider better event naming (show, checkValidity, displayValidity, complete)
 [] avoid RequestUpdate by integrating component

 [] is the a way to avoid/ignore nullable (e.g in final step everything will be set, why should i go x.y?.z?.value)
        

 [] support host:hidden {display:none}
 [] show step progress
 [] we might need a concept of Summary step, which shows the result of the Complete Step(or Summary and Conclusion step)

 [] how do i split the ui so can have any presentation but the same logic
 [] support adding steps dynamically
 [] reset/start 
 [] think about step reuse (e.g select-category-step) would either have to bind to parent or onprogress( wizard.cat = currentStep.cat )
 [] add checkValidity(wizardStep) on dca-wizard 
 [] consider auto-progress for steps that only have one option

Questions
[] in _getSteps, why does 
       this.shadowRoot.querySelector('slot').assignedNodes({flatten: true})[0];
       not work for finding DcaWizardStep[]. the current method may be descendent nodes, and we only want child


*/

@customElement("dca-wizard")
export class DcaWizard extends LitElement {

  @property({ type: String, attribute: "complete-label" }) CompleteLabel: string = "Complete";
  @state() activeStep: number = 0;
  @state() canNavigatePrevious: boolean = false;
  @state() private _isLastStep: boolean;

  constructor() {
    super();
  }

  protected _getSteps(): NodeListOf<DcaWizardStep> {
    return this.querySelectorAll("dca-wizard-step");
  }

  protected get Steps(): DcaWizardStep[] { return Array.from(this._getSteps()) }

  protected _getActiveStep(): DcaWizardStep {
    let activeStep: DcaWizardStep;
    this._getSteps().forEach(step => {
      if (step.step === this.activeStep) {
        activeStep = step;
      }
    });
    return activeStep;
  }

  protected setSteps() {
    this._getSteps().forEach(step => {
      step.hidden = step.step !== this.activeStep;
    });
    this.canNavigatePrevious = this._getActiveStep().step != 0;
  }

  previousStepHandler = async (event: Event) => {
    console.log("previous step")
    const activeStep = this._getActiveStep();
    var index = this.Steps.findIndex(i => i=== activeStep);
    var previousStep =  this.Steps[index-1];
    this.activeStep = previousStep.step;
    this._setLastStep();
  }

  // todo rename to progress handler
  protected nextStepHandler = async (event: Event) => {
    //get current
    const activeStep = this._getActiveStep();
    const isValid = activeStep.checkValidity();
    if (isValid) {
      if (activeStep.completeStep) {
        await activeStep.completeStep();
      }
      var index = this.Steps.findIndex(i => i=== activeStep);
      var nextStep =  this.Steps[index+1];
      this.activeStep = nextStep.step;

      this._setLastStep();
    }
  }


  private _setLastStep() {
    const lastStep = Array.from(this._getSteps()).slice(-1)[0];
    const currentStep = this._getActiveStep();
    this._isLastStep = lastStep == currentStep;
  }


  render() {
    // todo move setSteps out of here, but would need to make sure changing property activeStep triggers set-step
    this.setSteps();
    const currentStep = this._getActiveStep();
    return html`
         <header>
             <h3>
                ${currentStep.title}
             </h3>
         </header>
         <slot></slot>
         <hr/>
         <footer style="display:flex;justify-content: space-between;">
            <mwc-button label="Previous" icon="arrow_back" @click=${this.previousStepHandler} ?disabled=${!this.canNavigatePrevious} ></mwc-button>
            ${this._isLastStep ?
        html`<mwc-button label="${this.CompleteLabel}" ?hidden=${!this._isLastStep} trailingIcon icon="check_circle_outline" @click=${this.nextStepHandler}></mwc-button>` :
        html`<mwc-button label="Next" ?hidden=${this._isLastStep} trailingIcon icon="arrow_forward" @click=${this.nextStepHandler}></mwc-button>`}            
         </footer>
        `;
  }
}


@customElement("dca-wizard-step")
export class DcaWizardStep extends LitElement {
  @property({ type: Number }) step: number = 0;
  @property() title: string;

  // named generically as it may be used for Next|Finish
  @property({ attribute: false }) completeStep: () => Promise<void>;

  checkValidity(): boolean {
    const event = new CustomEvent<{ isValid: boolean }>('validate', { detail: { isValid: true }, bubbles: true, composed: true, cancelable: true });
    this.dispatchEvent(event);
    return event.detail.isValid
  }

  render() {
    return html`
            <slot></slot>
        `;
  }
}


import {
  provideFluentDesignSystem,
  fluentAnchor,
  fluentBadge,
  fluentButton,
  fluentProgress,
  fluentProgressRing,
  fluentSwitch,
  fluentTab,
  fluentTabPanel,
  fluentTabs,
  fluentTextField,
  fluentTextArea,
  fluentSelect,
  fluentOption,
  fluentMenu,
  fluentMenuItem,
  fluentDivider,
  fluentCombobox,
  fluentCheckbox

} from "@fluentui/web-components";


/*
import {css} from "@microsoft/fast-element"

import {
  provideFASTDesignSystem,
  fastDisclosure,
  disclosureStyles,
  

} from "@microsoft/fast-components";

provideFASTDesignSystem()
  .register(
      fastDisclosure({
        styles: (ctx, def) => css`
          ${disclosureStyles(ctx, def)}

          :host(.success) {
            --accent-foreground-rest: #0f5132;
            --accent-foreground-hover: #badbcc;
          }
  `})
  );
*/



//var accentBaseColor = "#DA1A5F";
//accentPalette.withDefault(PaletteRGB.from(SwatchRGB.from({r:16,g:124,b:16 })));

provideFluentDesignSystem()
  .register(
    fluentAnchor(),
    fluentBadge(),
    fluentButton(),
    fluentProgress(),
    fluentProgressRing(),
    fluentSwitch(),
    fluentTab(),
    fluentTabPanel(),
    fluentTabs(),
    fluentTextField(),
    fluentTextArea(),
    fluentSelect(),
    fluentOption(),
    fluentMenu(),
    fluentMenuItem(),
    fluentDivider(),
    fluentCombobox(),
    fluentCheckbox()
  );


import { css, html, LitElement, nothing } from "lit";
import { customElement, property, state } from "lit/decorators.js";

/*
 A twist on the traditional details component, 
  - allowing the details to exist outside of the element

 Usage
  <info-summary for="my-details">click me for info</info-summary>
    <info-details id="my-details">i can be anywhere in the document</info-details>

 - while is not good practice for components to directly talk to each other, I'll make an exception here.

todo:
[] needs show/hide animation
[] support details slot or(summary slot)
[] consider adding title and rendering a default style (like disclosure)

[] add title summary slot (which is any div that is wrapped in a info-summary)
[] add title text which creates a summary slot if not exists
 */


declare global {
  interface WindowEventMap {
    "info-detail-change": CustomEvent<InfoDetailChangeEvent>
  }
}
export interface InfoDetailChangeEvent {
  open: boolean
}

@customElement("info-summary")
export class InfoSummary extends LitElement {
  @property() for: string;

  @state() open: boolean;

  static styles = css`
     :host{
        display:block;
        cursor:pointer;
    }
     ::slotted(:hover){
         cursor:pointer
     }
    `

  connectedCallback() {
    super.connectedCallback()
    window.addEventListener("info-detail-change", this._infoDetailChangeEventHandler);
  }

  disconnectedCallback() {
    super.disconnectedCallback()
    window.removeEventListener("info-detail-change", this._infoDetailChangeEventHandler);
  }

  private _infoDetailChangeEventHandler = (event: CustomEvent<InfoDetailChangeEvent>) => {
    const target = this.#findTarget();
    if (target === event.target) {
      this.open = event.detail.open;
    }
  };

  clickEventHandler(event: Event) {
    const target = this.#findTarget();

    if (target instanceof InfoDetails) {
      target.toggle();
    }
  }

  render() {
    return html`
            <slot @click=${this.clickEventHandler}></slot>
            ${this.open ? html`<slot @click=${this.clickEventHandler} name="open"></slot>` : html`<slot @click=${this.clickEventHandler} name="closed"></slot>`}
            `
  }

  #findTarget = () => {
   var closestDocumentFragment = closestNode(this.parentNode, isDocumentFragment)
    if (closestDocumentFragment) {
      return closestDocumentFragment.getElementById(this.for)
    }
    return null;
  }
}

@customElement("info-details")
export class InfoDetails extends LitElement {
  @property({ type: Boolean, reflect: true }) open: boolean;

  static styles = css`
     :host{
        display:block
    }
    `
  toggle() {
    this.open = !this.open;
    const downloadReportEvent = new CustomEvent<InfoDetailChangeEvent>("info-detail-change", { detail: { open: this.open }, bubbles: true });
    this.dispatchEvent(downloadReportEvent);
  }

  render() {
    return html`
          ${this.open ? html`<slot></slot>` : nothing}
        `
  }
}




function isDocumentFragment(element: Node): element is DocumentFragment {
  return (element as any)?.getElementById;
}



/** Returns the first (starting at node) inclusive ancestor that matches selectors, and null otherwise. */
const closestNode = <T extends Node>(node: Node, predicate: (node: Node) => node is T): T | null => {
  if (predicate(node)) {
    return node;
  } else {
    if (node.parentNode) {
      return closestNode(node.parentNode, predicate);
    } else {
      return null;
    }
  }
};


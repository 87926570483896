import { LitElement, html } from 'lit';
import { customElement, property } from 'lit/decorators.js'
import { SnippetKeys } from './ctx-snippet-types';
import { snippets } from './ctx-snippets';
import { EditorBehaviour } from './ctx-editor-types';

@customElement('ctx-content-snippet-button')
export class CtxContentSnippetButton extends LitElement {
  @property() snippet: SnippetKeys;
  @property() behaviour: EditorBehaviour = "wrap";

  render() {
     var currentSnippet = snippets[this.snippet];
    return html`<ctx-content-button displayIcon=${ currentSnippet.icon } .value=${ currentSnippet[this.behaviour] } behaviour=${ this.behaviour }></ctx-content-button>`;
  }
}
/**
 * @license
 * Copyright 2021 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */

import {Routes} from './routes';


type URLParts = {pathname:string,search:string,hash:string}
// We cache the origin since it can't change
const origin = location.origin || location.protocol + '//' + location.host;

/**
 * A root-level router that installs global event listeners to intercept
 * navigation.
 *
 * This class extends Routes so that it can also have a route configuration.
 *
 * There should only be one Router instance on a page, since the Router
 * installs global event listeners on `window` and `document`. Nested
 * routes should be configured with the `Routes` class.
 */
export class Router extends Routes {
  // used to detect if it is a hashchange link, because popstate is called(an we want to ignore)
  // when clicking a hash link <a href="#data-sources">Data Sources</a> popstate is triggered
  isHashChangeNavigation:boolean= false;
  override hostConnected() {
    super.hostConnected();
    window.addEventListener('click', this._onClick);
    window.addEventListener('popstate', this._onPopState);
    window.addEventListener('lit-routes-goto', this._onGoTo)
    // Kick off routed rendering by going to the current URL
    this.goto(`${window.location.pathname}${window.location.search}${window.location.hash}`);
  }

  override hostDisconnected() {
    super.hostDisconnected();
    window.removeEventListener('click', this._onClick);
    window.removeEventListener('popstate', this._onPopState);
  }

  private _onClick = (e: MouseEvent) => {
    this.isHashChangeNavigation = false;
    const isNonNavigationClick =
      e.button !== 0 || e.metaKey || e.ctrlKey || e.shiftKey;
    if (e.defaultPrevented || isNonNavigationClick) {
      return;
    }

    const anchor = e
      .composedPath()
      .find((n) => (n as HTMLElement).tagName === 'A') as
      | HTMLAnchorElement
      | undefined;
    if (
      anchor === undefined ||
      anchor.target !== '' ||
      anchor.hasAttribute('download') ||
      anchor.getAttribute('rel') === 'external'
    ) {
      return;
    }

    const href = anchor.href;
    if (href === '' || href.startsWith('mailto:')) {
      return;
    }

    const location = window.location;
    if (anchor.origin !== origin) {
      return;
    }

    const path = `${anchor.pathname}${anchor.search}${anchor.hash}` 
    if(this.isRouteMatch(path))
    {
      
      if(this.isHashChange(window.location,anchor)){
        // at this point we are going to ignore any "hash routing" 
        // and let the browser take over
        // maybe be able to change this in the future (once we support child routes)
        console.log("router hashchange detected")
        this.isHashChangeNavigation = true;
      } else {
        e.preventDefault();
        if (href !== location.href) {
          window.history.pushState({}, '', href);
          this.goto(`${anchor.pathname}${anchor.search}${anchor.hash}`);
        }
      } 
      
    }
  };

  private _onPopState = (_e: PopStateEvent) => {
    if(this.isHashChangeNavigation) { //skip navigation on hashchange event, as currently "router enter()" can reload a report
      this.isHashChangeNavigation = false;
    } else {
      this.goto(`${window.location.pathname}${window.location.search}${window.location.hash}`);
    }
  };

  private _onGoTo= (_e:RouterGoToEvent) => {
    this.gotoWithPush(_e.url)
  }

  isHashChange = (currentUrl:URLParts,newUrl:URLParts) => {
    if(currentUrl.pathname === newUrl.pathname && currentUrl.search === newUrl.search)
    {
      return currentUrl.hash !== newUrl.hash;
    }
    return false;
  }
}

export class RouterGoToEvent extends Event {
  static readonly eventName = 'lit-routes-goto';
  onDisconnect?: () => void;

  url:string /*| URL*/;

  constructor(url:string/*| URL*/) {
    super(RouterGoToEvent.eventName, {
      bubbles: true,
      composed: true,
      cancelable: false,
    });
    this.url = url;
  }
}

declare global {
  interface WindowEventMap {
    [RouterGoToEvent.eventName]:RouterGoToEvent
  }
}

import { LitElement, html, css } from 'lit';
import { customElement, property, query } from 'lit/decorators.js'
import { CloseViewEvent } from 'src/events/close-view';

import { CloseButton } from './close-button';
CloseButton;
import { Divider } from '@fluentui/web-components';
import { actionBarStyles } from 'src/styles/action-bar';
Divider;

@customElement('confirmation-dialog')
export class ConfirmationDialog extends LitElement {
  static styles = [actionBarStyles,
    css`
      :host {
        display: contents;
      }

      .dialog-inner {
        display:flex;
        flex-direction:column;
      }

      .dialog-header {
        text-decoration:underline
      }

      .dialog-footer {
        margin-top:1em;
      }
     
    `
  ];

  @property({ type: String }) heading: string;

  @property({ type: String, attribute:"confirm-text" }) confirmText: string;

  @query("#dialog") dialog:HTMLDialogElement 
  okHandler = (e:Event) =>{
    this.dispatchEvent(new ConfirmedEvent());
    this.dialog.close(); 
  }

  openDialogHandler = (e:Event) =>{
    e.stopPropagation();
    e.preventDefault();
    this.dialog.showModal();
  }

  closeEventHandler = (e:CloseViewEvent) => { 
    this.dialog.close(); 
    e.stopPropagation();    
    e.preventDefault(); 
  }


  render() {
    return html`
      <slot @click=${this.openDialogHandler}></slot>
      <dialog id="dialog" @close-view=${this.closeEventHandler}>
        <div class="dialog-inner">
        <div class="action-bar dialog-header">
          <div> ${this.heading ?? "Confirm"}</div>
          <close-button class="right"></close-button>
        </div>
        <slot name="dialog-content">
          <div class="content">
          Are You Sure?
          </div>
        </slot>
        
        <div class="action-bar dialog-footer">
          <fluent-button class="push-right" @click=${this.okHandler} appearance="accent" >
            ${this.confirmText ??"Ok"}
          </fluent-button>
          <fluent-button @click=${this.closeEventHandler}   autofocus >
            Cancel
          </fluent-button>
        </div>
        </div>
        
      </dialog>
    `;
  }
}
export class ConfirmedEvent extends Event {
  static readonly eventName = 'confirmed';
  onDisconnect?: () => void;

  constructor() {
    super(ConfirmedEvent.eventName, {
      bubbles: true,
      composed: true,
      cancelable: false,
    });
  }
}

declare global {
  interface HTMLElementEventMap {
    [ConfirmedEvent.eventName]: ConfirmedEvent;
  }
}
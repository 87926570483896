import { LitElement, html } from 'lit';
import { customElement, property } from 'lit/decorators.js'
import { snippets } from './ctx-snippets';
import { SnippetKeys } from './ctx-snippet-types';

@customElement('ctx-content-snippets-select-option')
export class CtxContentSnippetsSelectOption extends LitElement {
  @property() snippet: SnippetKeys;

  render() {
    var currentSnippet = snippets[this.snippet];
    return html`<ctx-content-select-option displayIcon=${ currentSnippet.icon } displayText=${ currentSnippet.description } .value=${ currentSnippet.insert }></ctx-content-select-option>`;
  }
}
import { LitElement, html, css } from 'lit';
import { customElement } from 'lit/decorators.js'

@customElement('ctx-content-button-group')
export class CtxContentButtonGroup extends LitElement {
  static styles = [
    css`
        .button-group {
            align-items: center;
            cursor: pointer;
            display: flex;
            gap: 0.125rem;
            min-height: 2.25rem;
        }
    `
  ];

  render() {
    return html`<div class="button-group">
        <slot></slot>
    </div>`;
  }
}
import { LitElement, html, css, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { DownloadReportEvent } from './../download-manager';

declare global {
  interface WindowEventMap {
    "download-report": CustomEvent<DownloadReportEvent>
  }
}

/*
todo: 
consider controller for updating
consider litstate
new download-bar per download
dismiss animation
*/

import { DownloadBar } from './download-bar';
DownloadBar;
import { Button } from '@material/mwc-button'
Button;


@customElement('message-list')
export class MessageList extends LitElement {
  static styles = css`
    :host { 
      display: block; 
      background-color: rgb(250, 249, 248);
    }

 
    .danger {
      --mdc-theme-primary: var(--bootstrap-danger);
      color:var(--bootstrap-danger);
    }

    :host([hidden]) { 
      display: none; 
    }

    div {
        display:flex;
        flex-direction:row;
        align-items: center;
        padding-left:10px;
        padding-right:10px;
    }
    .content{
        width:100%;
        margin-left:20px;
        margin-right:20px;
    }
    `;

  @property()
  title = "Starting Download";

  private _progress = 0;
  private _visible = false;
  private _failed = false;
  connectedCallback() {
    super.connectedCallback()
    window.addEventListener("download-report", this._downloadReportEvent);
  }

  disconnectedCallback() {
    super.disconnectedCallback()
    window.removeEventListener("download-report", this._downloadReportEvent);
  }


  private _getDescriptionText = () => {
    switch (true) {
      case (this._progress < 15):
        return 'Initializing';
      case (this._progress < 25):
        return 'Queuing Report';
      case (this._progress < 45):
        return 'Analyzing Report';
      case (this._progress < 65):
        return 'Retrieving Data';
      case (this._progress < 75):
        return 'Preparing Download';
      case (this._progress >= 100):
        return 'Completed';
      default:
        return 'Rendering Report';
    }
  }

  private _downloadReportEvent = (event: CustomEvent<DownloadReportEvent>) => {
    console.log("DownloadReportEvent status Event triggered");
    console.log(event);
    this._progress = event.detail.progress;
    this.title = `Downloading: ${event.detail.reportName} `;

    if (event.detail.newDownload === true) {
      this._visible = true;
    }
    this._failed = event.detail.failed ? true : false;
    this.requestUpdate();
  }

  dismissHandler(event: Event) {
    console.log(event.target);
    // todo might be nicer to have an animation
    this._visible = false;
    this.requestUpdate();
  }

  dismissTemplate = html`<mwc-button @click=${this.dismissHandler}>Dismiss</mwc-button>`
  failedTemplate = html`<div class="danger"><span class="content">Error: Unable to download report</span>
    ${this.dismissTemplate}
  </div>`

  render() {
    return this._visible ?
      this._failed ? this.failedTemplate :
        html`
        <div>
            <download-bar class="content" title="${this.title}" progress=${this._progress} description="${this._getDescriptionText()}"></download-bar>${this.dismissTemplate}
        </div>`
      : nothing
  }

}
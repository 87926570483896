import { css, html, LitElement } from "lit";
import { customElement, property } from "lit/decorators.js";

@customElement('alert-message')
export class AlertMessage extends LitElement {
  static styles = css`

  :host {
    padding:1em;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    gap: 1em;
    box-shadow: rgba(0, 0, 0, 0.52) 0px 8px 10px -6px;
    border: 0.5px solid #f3f4f6;
    border-radius: 2px;
  }
  
  :host {
    background-color: white;
    color:#084298;
  }

  :host([level=warning]){
    color:#664d03;
  }
   
  :host([level=error]){
    color:#842029;
  }
  
  :host([height=flat]){
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px;
  }

  :host([height=short]){
    box-shadow: rgba(0, 0, 0, 0.52) 0px 2px 4px -2px;
  }

  :host([height=average]){
  }

  :host([height=tall]){
    box-shadow: rgba(0, 0, 0, 0.52) 0px 25px 50px -12px;
  }

  .end-wrapper
  {
    display:flex;gap: 1em;
  }
`;

  @property() level: "information" | "warning" | "error";
  @property() height: "flat" | "short" | "average" | "tall" = "average";
  
  render() {
    return html`
      <slot name="start">
          <mwc-icon slot="start">${this.#getIcon(this.level)}</mwc-icon>
      </slot>
      <slot></slot>
      <div class="end-wrapper">
      <slot name="end">
      </slot>
      </div>
    `
  }

  #getIcon = (level: string) => {
    switch (level) {
      case "information":
        return "info"
      case  "warning":
        return "warning";
      case "error":
        return "report";
      default:
        return "info"
    }
  }
}
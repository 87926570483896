import { html, css, LitElement, nothing } from "lit";
import { customElement, property, query } from "lit/decorators.js";

import "./category-edit";
import "./category-item";

import "@material/mwc-list/mwc-list";
import "@material/mwc-list/mwc-list-item";
import '@material/mwc-fab';

import { CategoryEdit, CategoryEventDetail } from "./category-edit";

import { createCategory } from "./../services";

interface CategoryDetail {
  description: string
  icon: string
  busy: boolean
  key?: number
}

@customElement("category-list")
export class CategoryList extends LitElement {

  @query("category-edit")
  private _newCategory: CategoryEdit;

  @property({ attribute: false })
  private categoryItems: CategoryDetail[] = [];

  createCategoryHandler = async (event: CustomEvent<CategoryEventDetail>) => {
    console.log(event.detail)
    const newItem: CategoryDetail = { ...event.detail, busy: true };
    this.categoryItems = [newItem, ...this.categoryItems];
    const response = await createCategory(event.detail.description, event.detail.icon);
    newItem.busy = false;
    newItem.key = response.key;
    this.requestUpdate();
  }

  displayCreateCategory = () => {
    this._newCategory.show();
  }

  render() {
    return html`
        <category-edit heading="Create Category" @accepted=${this.createCategoryHandler}></category-edit>    
        <mwc-fab extended  mini icon="add" label="Create Category" @click=${this.displayCreateCategory}></mwc-fab>
        <mwc-list>
          ${this.categoryItems.map(cat => html`<category-item  description="${cat.description}" icon="${cat.icon}" ?updating=${cat.busy} key=${cat.key} deletable >`)}
          <slot></slot>
        </mwc-list>
    `;
  }
}

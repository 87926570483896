/*
todo:
should be download-item
2px height https://nolanlawson.com/2021/01/03/options-for-styling-web-components/
*/

import { html, css, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '@material/mwc-linear-progress'

@customElement('download-bar')
export class DownloadBar extends LitElement {
  static styles = css`
  :host { 
      display: block; 
  }

  .description{
      color: rgb(96, 94, 92);
      font-size: 12px;
      line-height: 18px;
  }
  `;

  @property()
  title: string;
  @property()
  description: string;

  //0-100
  @property()
  progress: number;

  render() {
    const showBuffer = this.progress == 0 ? 0 : 1;
    return html`
    <div>${this.title}</div>
    <mwc-linear-progress determinate progress="${this.progress / 100}" buffer="${showBuffer}" ></mwc-linear-progress>
    <div class="description">${this.description}</div>
    `;
  }
}

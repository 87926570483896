import { LitElement, html } from 'lit';
import { customElement } from 'lit/decorators.js'

@customElement('ctx-content-snippets-select')
export class CtxContentSnippetsSelect extends LitElement {
    render() {
        return html`<ctx-content-select headingIcon="code" heading="Snippets">
           <slot></slot>
        </ctx-content-select>`;
    }
}
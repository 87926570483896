import { css, html, LitElement } from "lit";
import { customElement, property, query } from "lit/decorators.js";
import * as pbi from 'powerbi-client';
import appInsights from "src/application-insights";
import { IEmbedSettings } from "src/services";
import { EmbeddedPowerBiManager } from "./embedded-powerbi-manager";

EmbeddedPowerBiManager;
@customElement("thalamus-powerbi-report")
export class ThalamusPowerBiReport extends LitElement {

  static styles = css`
    :host{
      display:flex
    }

    embedded-powerbi-manager{
      flex-grow:1
    }
  `;

  constructor() {
    super();
  }

  @property({ attribute: "report-key", type: Number }) reportKey: number;
  @property({ attribute: "username", }) username: string;
  @property({ attribute: "facility-reference", }) facilityReference?: string;
  @property({ attribute: "embed-settings", type: Object }) embedSettings: IEmbedSettings;

  @query('#embedded-powerbi-manager-id') protected _powerbiManager: EmbeddedPowerBiManager

  private _report: pbi.Report;


  async firstUpdated() {
    this._report = await this._powerbiManager.loadReport({ reportKey: this.reportKey, username: this.username, facilityReference:this.facilityReference }, this.embedSettings);
  }

  getCurrentState = async (): Promise<string> => (await this._report.bookmarksManager.capture()).state;

  get report() {
    return this._report;
  }


  fullscreen = () => this._powerbiManager.fullscreen();

  loadContainerDataFromActivePage = async (containerName: string) => {
    const activePage = await this._report.getActivePage();
    const visuals = await activePage.getVisuals();
    // todo this is currently kinda bugged, as there is a ton of incorrectly named pages in the database
    var visual = visuals?.find((visual) => visual.name == containerName);
    if(visual == null)
    {
      appInsights.trackEvent({ name:"power-bi-report-visual-not-found", properties: { containerName: containerName, page:activePage?.name, reportId:activePage?.report?.config?.id, error:true }  } )
    }
    return await visual.exportData(pbi.models.ExportDataType.Summarized)
  }

  render = () => html`<embedded-powerbi-manager id="embedded-powerbi-manager-id" ></embedded-powerbi-manager>`;
}

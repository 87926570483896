import { css, html, LitElement, nothing } from "lit";
import { customElement, property } from "lit/decorators.js";

import "@material/mwc-icon"

@customElement('thalamus-card')
export class ThalamusCard extends LitElement {

  static styles = css`
  :host {

    box-sizing:border-box;
    display: block;

    border: 0px solid rgba(0,0,0,.125);
    border-radius: 0.25rem;

    background-color: var( --card-background-color);
    box-shadow: var(--card-box-shadow);
  }

  .body {
    padding: 1.25rem;
  }

  header {
    display:flex;
    flex-direction: row;
    justify-content: space-between;

    font-weight: 500;
    line-height: 1.2;
    font-size: 1.25rem;
    align-items: center;
    gap: 1em;
  }

  hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0,0,0,.1);
  }
`;

  @property() title: string;
  @property() icon: string;
  @property({ type:Boolean}) underline: boolean;
  render() {
    return html`
    <slot name="media"></slot>
    <div class="body">
      <header>
          <div>
            ${this.title}
          </div>
          <slot name="header-content"></slot>
          <mwc-icon>
              ${this.icon}
          </mwc-icon>
      </header>
      ${this.underline? html`<hr/>`:nothing}
      <slot></slot>
    </div>
`
  }
}
import { html, css, LitElement, nothing } from "lit";
import { customElement, property, query } from "lit/decorators.js";
import { Dialog } from "@material/mwc-dialog";
import { TextField } from "@material/mwc-textfield";
import { Icon } from "@material/mwc-icon";
import { LinearProgress } from "@material/mwc-linear-progress";
import { IconButton } from "@material/mwc-icon-button";

TextField; Dialog; Icon; LinearProgress; IconButton;

import { updateCategory, deleteCategory } from "./../services";
import { CategoryEdit, CategoryEventDetail } from "./category-edit";

@customElement("category-item")
export class CategoryItem extends LitElement {
  static styles = css`
    :host([hidden]){
      display:none
    }

    mwc-linear-progress{
      zoom:0.5
    }

    mwc-icon-button
    {
      position: relative;
      top: -11px; 
      right: 10px;
    }
  `;

  @property({ type: Number }) key: number;

  @property() description: string;

  @property() icon: string;

  @property({ type: Boolean }) deletable: boolean

  @property({ type: Boolean }) updating = false;


  @query("mwc-dialog")
  private _dialog: Dialog;

  @query("category-edit")
  private _categoryEdit: CategoryEdit;

  editCategory = (event: Event) => {
    this._dialog.show();
  };

  clickCategory = (event: CustomEvent) => {
    // todo we should reset changes here
    this._categoryEdit.show();
  };

  updateCategoryHandler = async (event: CustomEvent<CategoryEventDetail>) => {
    this.icon = event.detail.icon;
    this.description = event.detail.description
    this.updating = true;
    await updateCategory(this.key, this.description, this.icon);
    this.updating = false;
  };

  requestDeleteHandler = async (event: Event) => {
    event.cancelBubble = true;
    this.updating = true;
    await deleteCategory(this.key)
    this.updating = false;
    this.hidden = true;
  }

  render() {
    return html`
      <mwc-list-item @click=${this.clickCategory} graphic="icon" hasMeta>
        <mwc-icon slot="graphic">${this.icon}</mwc-icon>
        <span>${this.description}</span>
        <mwc-linear-progress ?closed=${!this.updating} indeterminate ></mwc-linear-progress>

        <!-- don't know why mwc-button stopped the parent ripple, but not not mwc-icon -->
        ${(this.deletable) ? html`<mwc-icon-button slot="meta" @click=${this.requestDeleteHandler} icon="delete"></mwc-icon-button>` : nothing}
       
      </mwc-list-item>
      <category-edit heading="Update Category" description=${this.description} icon=${this.icon} @accepted=${this.updateCategoryHandler} ></category-edit>
    `;
  }
}

import { EditorBehaviour } from "./ctx-editor-types";
import { SnippetInsertValue, SnippetWrapValue } from "./ctx-snippet-types";

export type EditorContent = {
  behaviour: EditorBehaviour, 
  value: string | SnippetInsertValue | SnippetWrapValue
}

export class EditorContentEvent extends Event {
    static readonly eventName = 'handle-editor-content';
    onDisconnect?: () => void;
  
    public readonly content:EditorContent;
    constructor(content:EditorContent) {
      super(EditorContentEvent.eventName, {
        bubbles: true,
        composed: true,
        cancelable: false,
      });
      this.content = content;
    }
  }
  
  declare global {
    interface HTMLElementEventMap {
      [EditorContentEvent.eventName]: EditorContentEvent;
    }
  }
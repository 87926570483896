import { css, html, LitElement } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";

/*

https://css-tricks.com/creating-custom-form-controls-with-elementinternals/

todo
    provider improved standard styles
    better documentation
todo low
    support <option data-value value>sub heading</option>
    obsolete input-select
*/


@customElement("dca-select")
export class DcaSelect extends LitElement {

  static styles = css`
    :host{
      display: inline-flex;
    }
    
    :host([hidden]){
      display:none;
    }

    input {
        box-sizing:border-box;
        flex-grow:1
    }
  `;

  @property({ type: Boolean }) required: boolean = false;
  @property({ type: Boolean }) autofocus: boolean = false;
  @property() placeholder: string;

  @query("input")
  private _input: HTMLInputElement;

  static formAssociated = true;

  //https://github.com/microsoft/TypeScript/issues/33218
  internals?: any;

  constructor() {
    super();
  }

  connectedCallback() {
    super.connectedCallback()
  }

  protected _options(): HTMLOptionElement[] {
    return Array.from(this.querySelectorAll("option"));
  }

  firstUpdated(value: any) {
    this.internals = (this as any).attachInternals();;

    /** Make sure validations are set up */
    /** This ensures our element always participates in the form */
    this._manageRequired();
  }

  _onInput(event: Event) {
    this._manageRequired();
  }

  protected _manageRequired() {
    var match = this._findOptionMatch(this._input.value)
    this.internals.setFormValue(match?.value);
    if (match == null && this.required) {
      this.internals.setValidity({
        valueMissing: true
      }, 'This field is required', this._input);

    } else {
      this.internals.setValidity({});
    }
  }

  protected _findOptionMatch(value: string): HTMLOptionElement {
    // potentially have more ways to match if there are more ways to set data
    // e.g <option data-value value>extra info</option>
    return this._options().find(option => option.innerText === value);
  }

  render() {
    return html`
            <input id="input" list="selectList" part="input" ?autofocus=${this.autofocus} placeholder=${this.placeholder} required  autocomplete="off"  @input="${this._onInput}" >
            <datalist id="selectList">
               ${this._options().map(option => html`<option data-value=${option.value} value="${option.text}"></option>`)}
            </datalist>
         `;
  }
}
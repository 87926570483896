import { IEmbedConfigurationBase } from "embed";
import { css, html, LitElement } from "lit";
import { customElement, query } from "lit/decorators.js";
import { IComponentEmbedConfiguration } from "service";
import { Embed } from "embed";

@customElement("embedded-powerbi-wrapper")
export class EmbeddedPowerBiWrapper extends LitElement {

  // iframe background-color may not be needed anymore... but leaving for now
  static styles = css`
    :host{
      display:block
    }
    
    :host([hidden]) { display: none }
    
    #reportContainer {
      height:100%;
      display:flex;
    }

    iframe {
      border: 0px;
      background-color: var(--background-theme-color);
    }

    iframe:fullscreen {
      background-color: var(--background-theme-color);
    }
  `;

  @query('#reportContainer') private reportContainer: HTMLElement

  
  pageChangedEventHandler = (event:CustomEvent) => {
    console.log("EmbeddedPowerBiWrapper page changed",event )
    const replayEvent = new CustomEvent(event.type, { bubbles:true,composed:true, detail: event.detail})
    this.dispatchEvent(replayEvent);
  }

  bootstrap = (config?: IComponentEmbedConfiguration | IEmbedConfigurationBase): Embed => window.powerbi.bootstrap(this.reportContainer, config);
  
  embed = (config?: IComponentEmbedConfiguration | IEmbedConfigurationBase): Embed => window.powerbi.embed(this.reportContainer, config);

  render = () => html`<div id="reportContainer" @pageChanged="${this.pageChangedEventHandler}"></div>`;
}

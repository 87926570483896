import { LitElement, html } from 'lit';
import { property, customElement } from 'lit/decorators.js';

import '@justinribeiro/lite-youtube';
@customElement('youtube-element')
export class YouTubeElement extends LitElement {
  @property() videoid: string;

  render() {
    return html`
        <lite-youtube videoid="${this.videoid}"></lite-youtube>
      `;
  }
}
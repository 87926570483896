import { LitElement, html, css } from 'lit';
import { property, customElement } from 'lit/decorators.js';
import { YouTubeElement } from './youtube-element';
YouTubeElement;

import './thalamus-card';
import { bootstrap4Styles } from '../styles/bootstrap4';
//https://codingyaar.com/bootstrap-video-card/
//render root is dom and we need bootstrap css for now

@customElement('youtube-card')
export class YouTubeCard extends LitElement {
  @property() title: string;
  @property() videoid: string;
  @property() description: string;

  static styles = [bootstrap4Styles,
    css`
    h5 {
      margin-block-start: 0;
    }
  `]

  render() {
    return html`
      <thalamus-card title=${this.title} style="--card-background-color:white">
        <youtube-element videoid="${this.videoid}" slot=media></youtube-element>
      </thalamus-card>
      `;
  }

}

import type { Switch  } from '@fluentui/web-components';
import 'src/components/fluentComponentRegistration';

import { LinearProgress } from "@material/mwc-linear-progress";
LinearProgress;
import { Icon } from "@material/mwc-icon";
Icon

import { html, css, LitElement, nothing } from "lit";
import { customElement, property, query } from "lit/decorators.js";
import { updateGroupUser } from 'src/services';
import styleSheet  from "src/styles/group-user-item-css"
import { classMap } from 'lit/directives/class-map.js';

@customElement("group-user-item")
export class GroupUserItem extends LitElement {
    static styles =  [styleSheet, css`
    :host([hidden]){
      display:none
    }

    mwc-linear-progress{
      zoom:0.5;
    }

    mwc-icon-button
    {
      position: relative;
      top: -11px; 
      right: 10px;
    }

    mwc-linear-progress.failure {
      --mdc-theme-primary: red;
    } 

  `];

    @property({ type: Number }) groupUserKey: number
    //todo change to drill-down
    @property({ type: Boolean }) drilldown: boolean
    @property({ type: Boolean }) readonly: boolean

    @property() username: string

    @property() failedState= false;

    @query("fluent-switch") private _switch: Switch;
    @query("mwc-linear-progress") private _progress: LinearProgress;

    requestToggleDrillDownHandler = async (event: Event) => {
      console.log("DrillDownHandler", event);
      await this.ProgressPromise<void>(updateGroupUser(this.groupUserKey, this._switch.checked))
    }

    private ProgressPromise = async <T>(innerPromise: Promise<T>): Promise<T> => {
        this.failedState = false;
        this._progress.indeterminate = true;
        try {
          const result = await innerPromise;
          return result;  
        } catch {
          debugger;
          this.failedState = true;
        } finally{
          this._progress.indeterminate = false;
          this._progress.progress =1;

        }
        
        
        
    }

    render() {
        return html`
<div class="grid-container" >
    <slot name="front-slot">${this.username}</slot>
    ${this.readonly ? this.drilldown ? html`<mwc-icon class="gp-col-2" slot="checked-message">lock_open</mwc-icon>`:nothing :html`
    <fluent-switch class="gp-col-2" @change=${this.requestToggleDrillDownHandler}   style="width:100px;min-height:28px" ?checked=${this.drilldown}>
        <mwc-icon slot="checked-message">lock_open</mwc-icon>
    </fluent-switch>
    `}
    <slot name="end-slot"></slot>
</div>
  <mwc-linear-progress  class="${classMap({failure:this.failedState})}"  style="width:100%;zoom:0.25; margin-bottom:35px;"></mwc-linear-progress>
    `;
    }
}



// OBSOLETE PLEASE USE <dca-select>

//requirements:
// input with datalist and  data-value-name
/*
    <input type="text" class="form-control" id="pratice-name-input" list="praticeList" placeholder="Choose Practice" autofocus required data-value-name="@Html.NameFor(m => m.AddPracticeKey)" >
    <datalist id="praticeList">
        @foreach (var practice in Model.Practices)
        {
            <option  data-value="@practice.Value">@practice.Text</option>
        }
    </datalist>
*/
export function RegisterInput(inputElement: HTMLInputElement) {
  //todo learn about memory deallocation
  new InputSelect(inputElement);
}


class InputSelect {
  inputElement: HTMLInputElement
  constructor(inputElement: HTMLInputElement) {
    this.inputElement = inputElement;
    this.applyRequiredDataListItem();
    this.submitFormData();
  }

  applyRequiredDataListItem() {
    this.inputElement.addEventListener("change", function (event: Event) {

      var datalist = this.list as HTMLDataListElement;
      // Determine whether an option exists with the current value of the input.
      const optionFound = findOption(datalist, this.value) != null;
      // use the setCustomValidity function of the Validation API
      // to provide an user feedback if the value does not exist in the datalist
      if (optionFound) {
        this.setCustomValidity("");
      } else {
        this.setCustomValidity("Please select an item in the list.");
      }
    });
  }


  submitFormData() {
    const inputName = this.inputElement.dataset["valueName"];
    if (inputName) {
      this.inputElement.form.addEventListener("formdata", (event: FormDataEvent) => {
        const option = findOption(this.inputElement.list as HTMLDataListElement, this.inputElement.value);
        event.formData.append(inputName, option.dataset["value"]);
      });
    }
  }
}


function findOption(datalist: HTMLDataListElement, value: string) {
  // todo why not for??
  for (var j = 0; j < datalist.options.length; j++) {
    if (value == datalist.options[j].value) {
      return datalist.options[j];
    }
  }
}


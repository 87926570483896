import { html, css, LitElement } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';

import { Dialog } from "@material/mwc-dialog";
import { TextField } from "@material/mwc-textfield";
import { Icon } from "@material/mwc-icon";
import { Button } from "@material/mwc-button";
Dialog; TextField; Icon; Button;

export interface CategoryEventDetail {
  description: string
  icon: string
}

@customElement('category-edit')
export class CategoryEdit extends LitElement {
  static styles = css`

  :root {
    --mdc-dialog-max-width :50vw
  }

  .dialog-content{
    display:flex;
    flex-direction:column;     
    width: 80vw;
    max-width: 493px;
    row-gap: 2rem; 
  }

  mwc-icon {
  --mdc-icon-size: 16px;
  }
  `;

  @property() heading: string;

  @property() description: string;

  @property() icon: string;

  show = () => this._dialog.show();

  @query("mwc-dialog") private _dialog: Dialog;

  @query("mwc-textfield") private _textField: TextField;

  @query("#iconName") private _iconName: TextField;

  #iconChanged = (event: Event) => {
    this.requestUpdate();
  };

  #accepted = async (event: Event) => {
    this.dispatchEvent(new CustomEvent<CategoryEventDetail>("accepted", { detail: { description: this._textField.value, icon: this._iconName.value } }));
  }

  #closed = (event: CustomEvent) => {
    let myEvent = new CustomEvent(event.type, {
      detail: event.detail,
      bubbles: true,
      composed: true
    });
    this.dispatchEvent(myEvent);
  }

  render() {
    return html`
    <mwc-dialog @closed=${this.#closed} heading="${this.heading}">
      <div class="dialog-content" >
        <mwc-textfield label="Name" value="${this.description}" required dialogInitialFocus ></mwc-textfield>
        <mwc-textfield
            id="iconName"
            label="Icon"
            value="${this.icon}"
            @input="${this.#iconChanged}"
            iconTrailing="${this._iconName?.value ?? this.icon}" required>
        </mwc-textfield>
        <span>Help: <a href="https://fonts.google.com/icons?selected=Material+Icons" target="_blank" >Material icon list<mwc-icon>open_in_new<mwc-icon></a></span>
      </div>
      <mwc-button
          slot="primaryAction"
          @click="${this.#accepted}"
          dialogAction="accepted">Save</mwc-button>
      <mwc-button slot="secondaryAction" dialogAction="close">Cancel</mwc-button>
    </mwc-dialog>
    `;
  }
}

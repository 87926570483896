import { LitElement, html, css } from 'lit';
import { customElement, property, queryAssignedElements } from 'lit/decorators.js'

export type  RadioButtonListChangeDetail = { value:any }

@customElement('radio-button-list')
export class RadioButtonList extends LitElement {
  static styles = [
    css`
      :host {
        display: block;
      }

      div {
        display: flex;
        flex-direction:row;
        
      }
      
      :host([grow]) ::slotted(*) { flex-grow: 1; }
    `
  ];

  @property({ attribute:"selected-value", type:Object  }) selectedValue: any;

  @property() label: string;

  @queryAssignedElements({selector: 'radio-button'}) buttons: Array<RadioButton>;


  public selectValue = (value:any) => {
    var valueButton = this.buttons.find(rb => rb.value == value)
    this.selectItem(valueButton);
  }

  private selectItem = (item:RadioButton) => {
    this.buttons.forEach(rb => {
      if(item ==rb)
      {
        rb.active = true
        this.selectedValue = rb.value
      } else{
        rb.active = false;
      }
    });
  }

  private clickHandler = (e: MouseEvent) => {
    this.selectItem(e.target as RadioButton)
    this.selectedValue
    this.dispatchEvent(new CustomEvent<RadioButtonListChangeDetail>("change",{ detail:{ value:this.selectedValue } }))
  };

  render() {
    return html`
    <div>${this.label}</div>
    <div @click=${this.clickHandler}>
      <slot></slot> 
      </div>`;
  }
}

@customElement('radio-button')
export class RadioButton extends LitElement {
  static styles = [
    css`
      :host {
        display: flex;
      }

      :host([grow]){
        flex-grow:1;
      }

      fluent-button {
        --control-corner-radius: 0;
        flex-grow:1;
      }
    `
  ];

  @property({ type:Boolean}) active: boolean;
  
  @property({ type: String }) value: string;
   
  private clickHandler = (event:Event) =>{
    
  }
  render() {
    var appearance = this.active ? "accent":"outline"
    return html`
    <fluent-button @click=${this.clickHandler} appearance=${appearance} role="switch" aria-checked=true >
      <slot name="start" slot="start"></slot>
      <slot name="end" slot="end"></slot>
      <label><slot></slot></label>
    </fluent-button>
    `;
  }
}

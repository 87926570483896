import { html, LitElement } from "lit";
import { customElement, property } from "lit/decorators.js";

/**
 * Attaches to click and calls show on target component
 */
@customElement("show-component-wrapper")
export class ShowComponentWrapper extends LitElement {
  @property() for: string;

  clickEventHandler(event: Event) {
    const target = document.getElementById(this.for);
    // todo should deal with target === null or show not existing
    (target as any).show();
  }

  render() {
    return html`
      <slot @click=${this.clickEventHandler}></slot>
    `
  }
}
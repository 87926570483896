import { LitElement, html, css } from 'lit';
import { customElement } from 'lit/decorators.js'
import { heading1Snippet, heading2Snippet, heading3Snippet, heading4Snippet, heading5Snippet, heading6Snippet } from './ctx-content-editor-examples';

@customElement('ctx-content-heading-select')
export class CtxContentHeadingSelect extends LitElement {
    static styles = [
        css`
            .heading-1 {
                font-size: 20px;
            }

            .heading-2 {
                font-size: 19px;
            }

            .heading-3 {
                font-size: 18px;
            }

            .heading-4 {
                font-size: 17px;
            }

            .heading-5 {
                font-size: 16px;
            }

            .heading-6 {
                font-size: 15px;
            }
        `
    ];

    render() {
        return html`<ctx-content-select headingIcon="short_text" heading="Headings">
            <ctx-content-select-option value=${ heading1Snippet }>
                <div class="heading-1">
                    Heading 1
                </div>
            </ctx-content-select-option>
            <ctx-content-select-option value=${ heading2Snippet }>
                <div class="heading-2">
                    Heading 2
                </div>
            </ctx-content-select-option>
            <ctx-content-select-option value=${ heading3Snippet }>
                <div class="heading-3">
                    Heading 3
                </div>
            </ctx-content-select-option>
            <ctx-content-select-option value=${ heading4Snippet }>
                <div class="heading-4">
                    Heading 4
                </div>
            </ctx-content-select-option>
            <ctx-content-select-option value=${ heading5Snippet }>
                <div class="heading-5">
                    Heading 5
                </div>
            </ctx-content-select-option>
            <ctx-content-select-option value=${ heading6Snippet }>
                <div class="heading-6">
                    Heading 6
                </div>
            </ctx-content-select-option>
        </ctx-content-select>`;
    }
}
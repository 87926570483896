import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js'
import { CloseViewEvent } from 'src/events/close-view';
import { IconButton } from '@material/mwc-icon-button';

IconButton;

@customElement('close-button')
export class CloseButton extends LitElement {
  static styles = [
    css`
      :host {
        display: block;
      }
    `
  ];

  render() {
    return html`
     <mwc-icon-button icon="close"  @click=${()=> this.dispatchEvent(new CloseViewEvent())}></mwc-icon-button>
    `;
  }
}

import { LitElement, html, css, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js'
import { SnippetInsertValue, SnippetWrapValue } from './ctx-snippet-types';
import { EditorContentEvent } from './ctx-editor-content-event';
import { EditorBehaviour } from './ctx-editor-types';

@customElement('ctx-content-button')
export class CtxContentButton extends LitElement {
  static styles = [
    css`
        .button {
            align-items: center;
            background-color: #FFFFFF;
            border: 0.5px solid #d1d5db;
            cursor: pointer;
            display: flex;
            justify-content: center;
            min-height: 2.25rem;
            min-width: 2.25rem;
            padding-inline: 0.5rem;
        }

        .button:hover {
          color: #0069d9;
        }

        .flex {
          align-items: center;
          display: flex;
          gap: 0.25rem;
        }
    `
  ];

  @property() behaviour: EditorBehaviour = "wrap";
  @property() displayIcon: string;
  @property() displayText: string;
  @property() value: SnippetInsertValue | SnippetWrapValue;

  private _clicked = async () => {
    this.dispatchEvent(new EditorContentEvent({
      behaviour: this.behaviour,
      value: this.value
    }));
  }

  render() {
    return html`<div class="button" @click=${this._clicked}>
        ${ this._renderDisplayContent() }
    </div>`;
  }

  private _renderDisplayContent() {
    return this.displayIcon || this.displayText ? html`<div class="flex">${this._renderDisplayIcon()} ${this.displayText}</div>` : html`<slot></slot>`;
  }

  private _renderDisplayIcon() {
    return this.displayIcon ? html`<mwc-icon>${this.displayIcon}</mwc-icon>` : nothing;
  }
}